import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TimePeriod, TimeUnitEnum } from 'types/enums';

export const isDatePast = (date: string | null): boolean => {
  if (date === null) return false;
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  return date < today;
};

export const dateRangeContainsPresentDate = (startDate: string, endDate: string): boolean => {
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  return startDate <= today && endDate >= today;
};

export const isDateFuture = (date: string): boolean => {
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  return date > today;
};

export const getDateTimePeriod = (
  startDate: string,
  endDate: string | null,
): 'past' | 'present' | 'future' => {
  if (isDatePast(endDate)) {
    return 'past';
  }
  if (isDateFuture(startDate)) {
    return 'future';
  }
  return 'present';
};

type TimeUnits = `${TimeUnitEnum}`;

export const getTimeUnitInMinutes = (time: number, unit: TimeUnits): number => {
  dayjs.extend(duration);

  const durationInUnit = dayjs.duration(time, unit);

  return durationInUnit.as(TimeUnitEnum.MINUTE);
};

export const getIntegerValueAndTimeUnitFromMinutes = (
  minutes: number,
): { value: number; timeUnit: TimePeriod } => {
  dayjs.extend(duration);

  const durationInMinutes = dayjs.duration(minutes, TimeUnitEnum.MINUTE);

  const checkIsNotZeroAndInteger = (number: number): boolean => number !== 0 && Number.isInteger(number);

  if (checkIsNotZeroAndInteger(durationInMinutes.asDays())) {
    return { value: durationInMinutes.asDays(), timeUnit: TimeUnitEnum.DAY };
  }
  if (checkIsNotZeroAndInteger(durationInMinutes.asHours())) {
    return { value: durationInMinutes.asHours(), timeUnit: TimeUnitEnum.HOUR };
  }

  return { value: durationInMinutes.asMinutes(), timeUnit: TimeUnitEnum.MINUTE };
};

// Returns first day of the month at 12AM and last day of the month as 11:59:59PM
export const getMonthRangeDates = (year?: number, month?: number): Date[] => {
  const date = new Date();
  return [
    new Date(year || date.getFullYear(), month || date.getMonth(), 1),
    new Date(year || date.getFullYear(), (month || date.getMonth()) + 1, 0, 23, 59, 59, 59),
  ];
};

// Returns first day of the month at 12AM and current day of the month as 11:59:59PM
export const getMonthRangeDatesMTD = (): Date[] => {
  const date = new Date();
  return [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 59),
  ];
};

// Returns current day at 12AM and current day at 11:59:59PM
export const getWeekRangeDates = (): [Date, Date] => {
  const date = new Date();
  const weekStartDate = date.getDate() - date.getDay();
  const weekStart = new Date(date.setDate(weekStartDate));
  const weekEnd = new Date(date.setDate(weekStartDate + 6));
  return [
    new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate(), 0, 0, 0, 0),
    new Date(weekEnd.getFullYear(), weekEnd.getMonth(), weekEnd.getDate(), 23, 59, 59, 59),
  ];
};

// Returns current day at 12AM and current day at 11:59:59PM
export const getDayRangeDates = (year?: number, month?: number, day?: number): Date[] => {
  const date = new Date();
  return [
    new Date(year || date.getFullYear(), month || date.getMonth(), day || date.getDate()),
    new Date(year || date.getFullYear(), month || date.getMonth(), day || date.getDate(), 23, 59, 59, 59),
  ];
};

// Accepts a date and various time units, returns a new date offset by the given time
export const setDateDifference = (
  originalDate: Date,
  years?: number,
  months?: number,
  days?: number,
  hours?: number,
  minutes?: number,
  seconds?: number,
): Date => {
  const date = new Date(originalDate);
  if (!!years) {
    date.setFullYear(date.getFullYear() + years);
  }
  if (!!months) {
    date.setMonth(date.getMonth() + months);
  }
  if (!!days) {
    date.setDate(date.getDate() + days);
  }
  if (!!hours) {
    date.setHours(date.getHours() + hours);
  }
  if (!!minutes) {
    date.setMinutes(date.getMinutes() + minutes);
  }
  if (!!seconds) {
    date.setSeconds(date.getSeconds() + seconds);
  }
  return date;
};

export const formatDate = (date: Date): string => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
